body {
    background: var(--second-color);
}
img{
    width: 100%;
    height: 100%;
    border-radius: 2rem;
    padding: 10px;
}
main {
    margin: 2% 10%;
    padding-bottom: 3rem;
}
.imgBox{
    width: auto;
}
.menu {
    padding: 2.5rem 0;
}
.title {
    text-align: center;
    margin-bottom: 2rem;
}
.section {
    border: 3px solid var(--main-color);
    border-radius: 2rem;
    width: 100%;
    height: 100%;
}
.colores {
    color: white;
    text-decoration: underline;
    margin-bottom: 1rem;
}
.underline {
    width: 5rem;
    height: 0.25rem;
    color: var(--second-color);
    background-color: var(--second-color);
    margin-left: auto;
    margin-right: auto;
}
article header {
    display: flex;
    align-items: center;
}
.btn-container {
    margin: 1.2rem 0;
    display: flex;
    /* opacity: scroll; */
    overflow: auto;
    flex-direction: row;
    overflow-x: scroll;
    flex-wrap: nowrap;
    /* overflow-y: hidden; */
    white-space: nowrap;
    scroll-behavior: smooth;
    justify-content: center;
    padding: 10px;
}
.btn-container::-webkit-scrollbar {
    /* width: 10px; */
    margin: 1rem;
    height: 10px;
    /* background: black; */
}
.btn-container::-webkit-scrollbar-thumb {
    background: var(--main-color);
    border-radius: 2rem;
    padding: 10px;
}
.filter-btn {
    background: var(--main-color);
    border-color: transparent;
    font-size: 1.1rem;
    /* text-transform: capitalize; */

    margin: 0 0.5rem;
    letter-spacing: 1px;
    padding: 0.375rem 0.75rem;
    /* font-size: 1.3rem; */
    color: var(--second-color);
    cursor: pointer;
    transition: var(--transition);
    border-radius: var(--radius);
}
.filter-btn:hover {
    background: #fbde8a;
    color: var(--second-color);
    transform: scale(1.1);
}
.section-center {
    width: 90vw;
    margin: 0 auto;
    max-width: 1170px;
    display: grid;
    gap: 10px;
    justify-items: center;
}
.menu-item {
    display: grid;
    /* gap: 1rem 2rem; */
    max-width: 25rem;
}
.photo {
    object-fit: cover;
    height: 200px;
    width: 100%;
    border: 0.25rem solid var(--clr-gold);
    border-radius: var(--radius);
    display: block;
}
.item-info header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    /* border-bottom: 0.5px solid var(--second-color); */
    text-align: start;
}
.item-info {
    /* display: flex; */
    /* flex-direction: colum; */
    align-items: baseline;
}
/* .header-h4 {
    color: white;
} */
p {
    font-size: 2rem;
}
.item-info h4 {
    margin-bottom: 0.5rem;
    text-align: start;
}
.price {
    color: var(--second-color);
    font-style: italic;
    padding-top: 2px;
}
.item-text {
    margin-bottom: 0;
    
    /* padding-top: rem; */
    color: var(--second-color);
    font-size: 1.5rem;
}
h2 {
    color: var(--main-color);
}
h4 {
    color: var(--second-color);
    font-size: 1.1rem;
    font-style: normal;
    margin-right: 1rem;
}
h5{
    padding: 0.5rem;
    font-size:1.5rem;
}

.menu-item {
    grid-template-columns: 520px 1fr;
    /* gap: 2rem 1.25rem; */
    max-width: 40rem;
    background: var(--main-color);
    padding: 1rem;
    border-radius: 1rem;
}
.section-center {
    width: 100%;
    grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 1440px) {
    .menu-item {
        grid-template-columns: 500px 1fr;
    }
    main {
        margin: 2% 5%;
    }
}
@media screen and (max-width: 1280px) {
    .menu-item {
        grid-template-columns: 400px 1fr;
    }
    main {
        margin: 2% 3%;
    }
}
@media screen and (max-width: 1024px) {
    .menu-item {
        grid-template-columns: 320px 1fr;
        /* gap: 0 1.25rem; */
    }
    main {
        margin: 2% 3%;
    }
    h4 {
        font-size: 1rem;
    }
    .item-text {
        font-size: 1rem;
    }
    .filter-btn {
        font-size: 0.9rem;
    }
}
@media screen and (max-width: 800px) {
    .menu-item {
        grid-template-columns: 450px 1fr;
        /* gap: 0 1.25rem; */
    }

    .section-center {
        width: 100%;
        grid-template-columns: 1fr;
    }
    .filter-btn {
        margin: 0px 5px;
    }
    .filter-btn:hover {
        transform: scale(1.05);
    }
}
@media screen and (max-width: 600px) {
    .menu-item {
        grid-template-columns: 380px 1fr;
        /* gap: 0 1.25rem; */
    }
    main {
        margin: 2% 1%;
    }
    .btn-container {
        justify-content: flex-start;
    }
}
@media screen and (max-width: 460px) {
    .menu-item {
        grid-template-columns: 320px 1fr;
        /* gap: 0 1.25rem; */
    }
    main {
        margin: 2% 1%;
    }
    .section-center {
        width: 100%;
        grid-template-columns: 1fr;
    }
    .filter-btn {
        margin: 0px 5px;
    }
    .filter-btn:hover {
        transform: scale(1.05);
    }
}
@media screen and (max-width: 425px) {
    .menu-item {
        grid-template-columns: 280px 1fr;
        /* gap: 0 1.25rem; */
    }
}
@media screen and (max-width: 375px) {
    .menu-item {
        grid-template-columns: 240px 1fr;
        /* gap: 0 1.25rem; */
    }
}
@media screen and (max-width: 325px) {
    .menu-item {
        grid-template-columns: 200px 1fr;
        /* gap: 0 1.25rem; */
    }
}
