:root {
    /* dark shades of primary color*/
    --clr-primary-1: hsl(286, 28%, 21%);
    --clr-primary-2: hsl(293, 28%, 29%);
    --clr-primary-3: hsl(280, 28%, 37%);
    --clr-primary-4: hsl(277, 28%, 45%);
    /* primary/main color */
    --clr-primary-5: hsl(270, 31%, 52%);
    /* lighter shades of primary color */
    --clr-primary-6: hsl(272, 31%, 60%);
    --clr-primary-7: hsl(276, 31%, 67%);
    --clr-primary-8: hsl(271, 31%, 74%);
    --clr-primary-9: hsl(276, 31%, 81%);
    --clr-primary-10: hsl(278, 31%, 88%);
    /* darkest grey - used for headings */
    --clr-grey-1: hsl(209, 61%, 16%);
    --clr-grey-2: hsl(211, 39%, 23%);
    --clr-grey-3: hsl(209, 34%, 30%);
    --clr-grey-4: hsl(209, 28%, 39%);
    /* grey used for paragraphs */
    --clr-grey-5: hsl(210, 22%, 49%);
    --clr-grey-6: hsl(209, 23%, 60%);
    --clr-grey-7: hsl(211, 27%, 70%);
    --clr-grey-8: hsl(210, 31%, 80%);
    --clr-grey-9: hsl(212, 33%, 89%);
    --clr-grey-10: hsl(210, 36%, 96%);
    --clr-white: #fff;
    --clr-red-dark: hsl(360, 67%, 44%);
    --clr-red-light: hsl(360, 71%, 66%);
    --clr-green-dark: hsl(125, 67%, 44%);
    --clr-green-light: hsl(125, 71%, 66%);
    --clr-black: #222;
    --transition: all 0.3s linear;
    --spacing: 0.1rem;
    --radius: 0.25rem;
    --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    --max-width: 1170px;
    --fixed-width: 620px;
    --purple: #7058a4;
    --background: #b7bca6;
    --main-color: #f3c641;
    --second-color: #451819;
    --font: "Kanit", sans-serif;
}
.dark-theme {
    --clr-bcg: #282c35;
    --clr-font: #fff;
    --clr-primary: #282c35;
    --clr-bonus: #7058a4;
}
.light-theme {
    --clr-font: #282c35;
    --clr-bcg: #fff;
    --clr-primary: White;
    --clr-bonus: gold;
}
/*
=============== 
Global Styles
===============
*/
html {
    scroll-behavior: smooth;
    height: 100%;
}
*,
::after,
::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    /* background: #050505; */
    line-height: 1.5;
    font-size: 0.875rem;
    transition: all 0.4s linear;
    background: var(--background);
    height: 100%;
    /* background-color: var(--clr-bcg); */
}
ul {
    list-style-type: none;
}
a {
    text-decoration: none;
    font-family: var(--font);
}
hr {
    border: none;
    border-top: 1px solid var(--clr-grey-8);
}
h1,
h2,
h3,
h4,
h5 {
    letter-spacing: var(--spacing);
    /* text-transform: capitalize; */
    line-height: 1.25;
    margin-bottom: 0.75rem;
    color: white;
    font-family: var(--font);
}
h1 {
    font-size: 2.5rem;
}
h2 {
    font-size: 2rem;
}
h3 {
    font-size: 1.5rem;
}
h4 {
    font-size: 1.25rem;
}
h5 {
    font-size: 0.875rem;
}
@media screen and (max-width: 750px) {
    h5 {
        font-size: 0.6rem;
    }
}
p {
    color: var(--clr-grey-3);
    font-family: var(--font);
}

@media screen and (min-width: 800px) {
    h1 {
        font-size: 3rem;
    }
    h2 {
        font-size: 2.5rem;
    }
    h3 {
        font-size: 2rem;
    }
    h4 {
        font-size: 1.5rem;
    }
    h5 {
        font-size: 1rem;
    }
    body {
        font-size: 1rem;
    }
    h1,
    h2,
    h3,
    h4 {
        line-height: 1;
    }
}
/*  global classes */

/* section */

.shadow {
    -webkit-box-shadow: 0px 10px 13px -7px #000000,
        5px 5px 15px 5px rgba(0, 0, 0, 0);
    box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
}
/* .section {
    padding: 5rem 0;
} */
.section-center {
    width: 90vw;
    margin: 0 auto;
    max-width: var(--max-width);
}

@media screen and (min-width: 992px) {
    .section-center {
        width: 95vw;
    }
}

.text-center {
    text-align: center;
}
@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.loading {
    width: 6rem;
    height: 6rem;
    margin: 0 auto;
    margin-top: 10rem;
    border-radius: 50%;
    border: 4px solid #ccc;
    border-top-color: var(--clr-primary-5);
    animation: spinner 0.6s linear infinite;
}

.btn {
    text-transform: uppercase;
    background: var(--main-color);
    color: var(--second-color);
    padding: 0.375rem 0.75rem;
    letter-spacing: var(--spacing);
    display: inline-block;
    font-weight: 400;
    transition: var(--transition);
    font-size: 0.875rem;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: var(--radius);
    border-color: transparent;
}
.btn:hover {
    color: var(--clr-primary-1);
    background: #fbde8a;
}
@media screen and (max-width: 820px) {
    .btn {
        text-transform: uppercase;
    background: var(--main-color);
        color: var(--second-color);
        padding: 0.375rem 0.75rem;
        letter-spacing: var(--spacing);
        display: inline-block;
        font-weight: 400;
        transition: var(--transition);
        font-size: 0.65rem;
        cursor: pointer;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
        border-radius: var(--radius);
        border-color: transparent;
    }
    .btn:hover {
    color: var(--clr-primary-1);
        background: #fbde8a;
    }
    h3 {
        font-size: 1rem;
    }
}
.title {
    text-align: center;
}
.title .underline {
    width: 6rem;
    height: 0.25rem;
    background: #49a6e9;
    background: var(--main-color);
    margin-left: auto;
    margin-right: auto;
}
.underline2{
    width: 15%;
        height: 0.25rem;
        background: #49a6e9;
        background: var(--main-color);
        margin-left: auto;
        margin-right: auto;
}
.page-100 {
    min-height: calc(100vh - 10rem);
    padding: 5rem 0;
}
.page {
    min-height: calc(100vh - (20vh + 10rem));
}
.mousey {
    /* display: none; */
    position: fixed;
    bottom: 25px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 30px;
    height: 48px;
    border: 2px solid white;
    border-radius: 20px;
    opacity: 0;
    -webkit-animation: mousey 4s ease 3s infinite;
    animation: mousey 4s ease 3s infinite;
    -webkit-animation-fill-mode: backwards;
    animation-fill-mode: backwards;
    z-index: 5;
}
/* header {
    background-image: url("../images/doodad.png");
} */
@media (min-width: 768px) {
    .mousey {
        display: block;
    }
}
@media (max-width: 680px) {
    .mousey {
        display: block;
    
    }
   
}
@media (max-height: 520px) {
    .mousey {
        display: none;

    }

}

.mousey .scroller {
    position: absolute;
    top: 8px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 3px;
    height: 8px;
    background-color: white;
    -webkit-animation: mousey-scroller 4s ease 3s infinite;
    animation: mousey-scroller 4s ease 3s infinite;
    -webkit-animation-fill-mode: backwards;
    animation-fill-mode: backwards;
}

@-webkit-keyframes mousey {
    0% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    90% {
        opacity: 0;
    }
}

@keyframes mousey {
    0% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    90% {
        opacity: 0;
    }
}

@-webkit-keyframes mousey-scroller {
    0% {
        top: 8px;
    }
    30% {
        top: 8px;
    }
    80% {
        top: 14px;
    }
    90% {
        top: 14px;
    }
}

@keyframes mousey-scroller {
    0% {
        top: 8px;
    }
    30% {
        top: 8px;
    }
    80% {
        top: 14px;
    }
    90% {
        top: 14px;
    }
}
