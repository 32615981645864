.sidebar {
    position: fixed;

    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background: var(--second-color);
    display: grid;
    grid-template-rows: auto 1fr auto;
    row-gap: 1rem;
    box-shadow: var(--clr-red-dark);
    transform: translate(+100%);
    transition: all 0.3s linear;
    color: var(--main-color);
    z-index: 100;

    /* border-bottom-right-radius: 2rem; */
    /* transition: smooth; */
}
.show-sidebar {
    transform: translate(0%);

    transition: all 0.3s linear;
    /* transform: translate(-100%); */

    z-index: 100;
}
.social-icons {
    font-size: 2rem;
    display: flex;
}
.close-btn {
    position: absolute;
    background: none;
    margin: 1rem;
    border: none;
    font-size: 2rem;
    color: var(--main-color);
}
.close-btn:hover {
    cursor: pointer;
    transform: scale(1.1);
}
.sidebar-header {
    margin: auto;
}
.colore {
    color: var(--main-color);
    border: none;
    font-size: 2rem;
}
@media screen and (max-width: 550px) {
    .sidebar {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        background: var(--second-color);
        display: grid;
        grid-template-rows: auto 1fr auto;
        row-gap: 1rem;
        box-shadow: var(--clr-red-dark);
        transition: all 0.4s linear;
        /* transform: translate(-100%); */
        z-index: 100;
        color: var(--main-color);
        /* transition: smooth; */
    }
    .show-sidebar {
        transform: translate(0);
        transition: all 0.4s linear;
        z-index: 100;
    }
}
@media screen and (max-width: 380px) {
    .nav-header > img {
        display: none;
    }
}
